<div class="loading">
    <em class="icon icon-loading"></em>
</div>

<span class="title">{{ 'Automatic analysis of your roof may take a little longer.' | translate }}</span>
<span class="sub-title">{{ 'As soon as the panels design is ready, we will inform you by e-mail.' | translate }}</span>

<div class="graphic">
    <svg xmlns="http://www.w3.org/2000/svg" width="1024" height="768" viewBox="0 0 1024 768" fill="none">
        <path id="lighter1MainColor" d="M273.352 484.545C277.062 477.759 279.175 469.979 279.175 461.697C279.175 451.618 276.03 442.281 270.696 434.581C281.91 428.384 289.506 416.444 289.506 402.715C289.506 386.021 278.265 371.988 262.954 367.704C268.747 359.807 272.208 350.096 272.208 339.549C272.208 313.222 250.894 291.882 224.601 291.882C224.04 291.882 223.495 291.949 222.94 291.965C224.017 288.527 224.601 284.868 224.601 281.073C224.601 271.742 221.065 263.262 215.298 256.821C216.253 252.927 216.818 248.878 216.818 244.689C216.818 216.692 194.15 194 166.189 194C138.232 194 115.566 216.692 115.566 244.689C115.566 253.207 117.689 261.215 121.396 268.26C106.616 272.604 95.7699 286.079 95.3404 302.18C85.6212 308.712 79.2235 319.81 79.2235 332.409C79.2235 339.296 81.169 345.708 84.4874 351.2C81.1446 357.718 79.2235 365.092 79.2235 372.921C79.2235 373.492 79.2902 374.048 79.3113 374.615C59.0464 380.092 44.1154 398.568 44.1154 420.587C44.1154 444.644 61.9338 464.482 85.0632 467.733C82.8705 472.417 81.6082 477.625 81.6082 483.143C81.6082 484.56 81.709 485.954 81.8652 487.327C81.779 487.327 81.696 487.314 81.6082 487.314C55.3149 487.314 33.9991 508.655 33.9991 534.98C33.9991 561.304 55.3149 582.645 81.6082 582.645C93.6194 582.645 104.56 578.157 112.939 570.807C128.664 586.585 150.411 596.35 174.435 596.35C196.663 596.35 216.928 587.987 232.294 574.243C240.578 580.253 250.732 583.841 261.743 583.841C289.518 583.841 312.032 561.299 312.032 533.49C312.034 509.691 295.52 489.796 273.352 484.545Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path id="darkerMainColor" d="M164.913 815V265.49" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M145.726 329.28L165.588 349.166L188.571 326.153" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M109.483 497.932H137.573V465.389" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M145.726 410.83L165.588 430.712L208.32 387.928" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M67.4533 425.967L165.586 524.215L242.74 446.969" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M84.9149 564.46L165.696 645.338L261.318 543.321" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M126.086 543.321V604.899" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path d="M232.308 543.321L238.87 567.271" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
        <path id="lighter2MainColor" d="M197.815 553.739V596.141H521.316V665.919H197.815V793.037H918.362V553.739H197.815Z" [attr.fill]="colorsService.lighter2MainColor()"/>
        <path d="M876.202 132.572L442.832 132.458L152.862 511.497L917.879 512.893L1205.96 132.674L968.853 132.945" [attr.fill]="colorsService.lighter2MainColor()"/>
        <path d="M876.202 132.572L442.832 132.458L152.862 511.497L917.879 512.893L1205.96 132.674L968.853 132.945" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path id="mainColor" d="M942.102 523.476L1205.96 170.216V132.674" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M152.862 512.359V555.578L917.879 555.152V512.893" [attr.fill]="colorsService.lighter2MainColor()"/>
        <path d="M152.862 512.359V555.578L917.879 555.152V512.893" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M942.102 791.689V523.476" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M197.815 791.69V665.919" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M197.815 596.141V553.739" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M215.757 596.14V665.918" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M413.871 596.14V665.918" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M197.815 596.14H521.316V665.918H197.815" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M918.361 793.038V555.915" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M928.92 78.6682V185.959L968.852 135.25V78.6682H928.92Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path d="M723.486 596.14H701.698V790.859H723.486V596.14Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path d="M918.363 555.564H197.815V566.087H918.363V555.564Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path d="M876.204 78.6682V185.816L928.921 185.959L968.854 135.25V78.6682H928.633H876.204Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M928.92 185.816V78.6682" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M871.318 68.8828H928.921H974.218V78.6683H928.921H871.318V68.8828Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M827.886 596.14H701.698V790.859H827.886V596.14Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M1365 657.256H942.102" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M1364.12 523.654H942.102" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
        <path d="M472.245 322.309L370.466 454.669H359.075L472.552 307.681H598.321L587.484 322.235L472.245 322.309Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path d="M359.075 454.669H485.747L598.321 307.681H472.552L359.075 454.669Z" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M524.673 322.235L422.937 454.67" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M802.277 322.309L700.496 454.669H689.105L802.586 307.681H928.356L917.519 322.235L802.277 322.309Z" [attr.fill]="colorsService.lighter1MainColor()"/>
        <path d="M689.105 454.669H815.781L928.356 307.681H802.586L689.105 454.669Z" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M854.706 322.235L752.967 454.67" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>

        <g>
            <path class="step-1-animation" opacity="0.4" d="M1190.15 217.415H340L381.746 162.623H1231.89L1190.15 217.415Z" fill="url(#gradient0)"/>
            <path class="step-1-animation" d="M381.746 159H1231.89" stroke="#44B2E5" stroke-miterlimit="10"/>
            <path class="step-1-animation" opacity="0.6" d="M381.746 159H1231.89" stroke="#AFE1F6" stroke-width="3" stroke-miterlimit="10"/>

            <path d="M876.204 540.949H934.118L968.854 495.425" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M125.72 490.901L87.5322 540.95H155.488" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M484.715 102.799H421.861L391.299 142.853" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
        </g>

        <defs>
            <linearGradient id="gradient0" x1="785.947" y1="217.415" x2="785.947" y2="162.622" gradientUnits="userSpaceOnUse">
                <!--suppress RequiredAttributes -->
                <stop stop-color="#44B2E5" stop-opacity="0"/>
                <stop offset="1" stop-color="#57CBF5"/>
            </linearGradient>
        </defs>
    </svg>
</div>
