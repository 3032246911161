import { DecimalPipe, NgOptimizedImage } from '@angular/common';
import {
    Component,
    computed,
    effect,
    input,
    InputSignal,
    model,
    ModelSignal,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2,
    Select2Data,
    Select2Option,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';

import {
    Module,
    ResultResponse,
    TenantResponse,
} from '../../../../domain/api-result';
import { CountInputComponent } from '../../components/count-input/count-input.component';
import { SliderComponent } from '../../components/slider/slider.component';
import { ToggleComponent } from '../../components/toggle/toggle.component';

interface Select2DataObject {
    [key: string]: Select2Option;
}

@Component({
    selector: 'app-energy-storage',
    standalone: true,
    imports: [
        CountInputComponent,
        ToggleComponent,
        NgOptimizedImage,
        TranslateModule,
        SliderComponent,
        DecimalPipe,
        Select2,
    ],
    templateUrl: './energy-storage.component.html',
    styleUrl: './energy-storage.component.scss',
})
export class EnergyStorageComponent {
    public battery: ModelSignal<Select2Option | undefined> = model.required();
    public batteryEnabled: ModelSignal<boolean> = model(false);
    public batteryCount: ModelSignal<number> = model(0);
    public energyStorageSliderValue: ModelSignal<number> = model(0);
    public chargingCarSliderValue: ModelSignal<number> = model(0);

    public modules: InputSignal<Module[] | undefined> = input();
    public result: InputSignal<ResultResponse | undefined> = input();
    public tenant: InputSignal<TenantResponse | undefined> = input();
    public isReadonly: InputSignal<boolean> = input(false);

    protected batteryOptions: WritableSignal<Select2DataObject> = signal({});
    protected batteryValue: WritableSignal<Select2UpdateValue> = signal(false);

    protected batterySelect2Data: Signal<Select2Data> = computed(
        (): Select2Data => {
            return Object.values(this.batteryOptions());
        },
    );

    constructor() {
        effect((): void => {
            const modules: Module[] | undefined = this.modules();

            this.batteryOptions.set({});

            if (undefined === modules || 0 === modules.length) {
                return;
            }

            const batteryOptions: { [key: string]: Select2Option } = {};

            modules.forEach((module: Module): void => {
                if ('battery' !== module.category) {
                    return;
                }

                batteryOptions[module.id] = {
                    label: this.getModuleLabel(module),
                    data: module.name,
                    value: module.id,
                };
            });

            this.batteryOptions.set(batteryOptions);

            if (0 < this.batterySelect2Data().length) {
                const value: Select2Value = (
                    this.batterySelect2Data()[0] as Select2Option
                ).value;

                this.batteryUpdate({
                    value: value,
                } as Select2UpdateEvent);
            }
        });
    }

    private getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.tenant()?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    private get yearlyEnergyDcKwh(): number {
        return this.result()?.solarPanelConfiguration?.yearlyEnergyDcKwh ?? 0;
    }

    protected get energyStorageConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.energyStorageSliderValue() / 100);
    }

    protected get recommendedEnergyStorageSize(): number {
        return this.energyStorageConsumption / 360;
    }

    protected get carChargingConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.chargingCarSliderValue() / 100);
    }

    protected get solarEnergyCarRange(): number {
        return this.carChargingConsumption / 0.18;
    }

    protected changeBatteryCount(count: number): void {
        this.batteryCount.set(count);
    }

    protected toggleSection(active: boolean): void {
        this.batteryEnabled.set(active);
    }

    protected updateEnergyStorageSlider(value: number): void {
        this.energyStorageSliderValue.set(value);

        this.chargingCarSliderValue.set(
            Math.min(this.chargingCarSliderValue(), 100 - value),
        );
    }

    protected updateChargingCarSlider(value: number): void {
        this.chargingCarSliderValue.set(value);

        this.energyStorageSliderValue.set(
            Math.min(this.energyStorageSliderValue(), 100 - value),
        );
    }

    protected batteryUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        const option: Select2Option | undefined =
            this.batteryOptions()?.[event.value];

        if (!option) {
            return;
        }

        this.batteryValue.set(option.value);
        this.battery.set(option);
    }
}
