<div class="aside-company-data" [class.in-sidebar]="isInSidebar()">
    @if (!hideLogo()) {
        <app-loading [isLoading]="tenantResource()?.isLoading() ?? false" [showContent]="true">
            @if (configService.companyLogo(); as companyLogo) {
                <img [src]="companyLogo" [width]="logoWidth()" [height]="logoWidth()" [alt]="companyName()">
            } @else if (tenantResource()?.isLoading() ?? false) {
                <img ngSrc="/assets/company.webp" priority width="54" height="54" [alt]="companyName()">
            } @else {
                <img src="/assets/company@2x.webp" [width]="logoWidth()" [height]="logoWidth()" [alt]="companyName()">
            }
        </app-loading>
    }

    @if (!hideName()) {
        <app-loading [isLoading]="tenantResource()?.isLoading() ?? false" [showContent]="true">
            <p id="company-name">{{ companyName() }}</p>
        </app-loading>
    }
</div>
