import { NgOptimizedImage } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    InputSignal,
    ResourceRef,
    Signal,
} from '@angular/core';

import { TenantResponse } from '../../../domain/api-result';
import { ConfigService } from '../../../services/config.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-aside-company-data',
    standalone: true,
    imports: [NgOptimizedImage, LoadingComponent],
    templateUrl: './aside-company-data.component.html',
    styleUrl: './aside-company-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideCompanyDataComponent {
    protected readonly configService: ConfigService = inject(ConfigService);

    public tenantResource: InputSignal<
        ResourceRef<TenantResponse | undefined> | undefined
    > = input.required();
    public isInSidebar: InputSignal<boolean> = input(false);

    protected companyName: Signal<string> = computed((): string => {
        return this.tenantResource()?.value()?.name ?? '';
    });

    protected logoWidth: Signal<number> = computed((): number => {
        return this.configService.logoWidth();
    });

    protected hideLogo: Signal<boolean> = computed((): boolean => {
        return this.configService.hideLogo();
    });

    protected hideName: Signal<boolean> = computed((): boolean => {
        return this.configService.hideName();
    });
}
