<h4>{{ 'The AI algorithm will analyze your roof and energy consumption and prepare a project and offer for you.' | translate }}</h4>
<p>{{ 'Provide address and energy consumption.' | translate }}</p>

<app-loading [isLoading]="tenantResource().isLoading()">
    <form [formGroup]="step1Form" [class.has-battery]="tenantResource().value()?.has_battery_in_storage" (ngSubmit)="formSubmit()">
        <div class="form-group" [class.have-errors]="address?.invalid && !address?.untouched">
            <label for="address">{{ 'Address' | translate }}</label>
            <div class="input-group">
                <em class="icon icon-address"></em>
                <input autocomplete="off" autofocus type="text" #autocompleteInput formControlName="address" [placeholder]="'Property Address' | translate" class="field-icon field-icon-address sentry-mask" id="address">
            </div>
            @if (address?.invalid && !address?.untouched) {
                <div class="form-errors">
                    @if (address?.errors?.['required']) {
                        <div>{{ 'Address is required.' | translate }}</div>
                    }
                </div>
            }
        </div>
        <div class="form-group" [class.have-errors]="bill?.invalid && !bill?.untouched">
            <label for="bill">{{ 'Electric Bill' | translate }}</label>
            <div class="input-group">
                <em>{{ tenantResource().value()?.currency_code }}</em>
                <input type="number" formControlName="bill" placeholder="100" min="0" step="0.01" id="bill">
            </div>
            @if (bill?.invalid && !bill?.untouched) {
                <div class="form-errors">
                    @if (bill?.errors?.['required']) {
                        <div>{{ 'Electric Bill is required.' | translate }}</div>
                    }
                </div>
            }
        </div>
        @if (tenantResource().value()?.has_battery_in_storage && step1Form.contains('equipment')) {
            <div class="form-group" [class.have-errors]="equipment?.invalid && !equipment?.untouched">
                <label for="equipment">{{ 'Equipment' | translate }}</label>
                <select2
                    id="equipment"
                    required
                    formControlName="equipment"
                    [value]="1"
                    [data]="equipmentOptions()"
                />
                @if (equipment?.invalid && !equipment?.untouched) {
                    <div class="form-errors">
                        @if (equipment?.errors?.['required']) {
                            <div>{{ 'Equipment Bill is required.' | translate }}</div>
                        }
                    </div>
                }
            </div>
        }
        <div class="step">{{ 'Step' | translate }} 1/2</div>
        <div class="buttons">
            <button class="btn btn-primary btn-with-icon" type="submit">{{ 'Continue' | translate }} <em class="icon icon-chevron-right-full"></em></button>
        </div>
    </form>
</app-loading>
