import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    OnDestroy,
    resource,
    ResourceLoaderParams,
    ResourceRef,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Option } from 'ng-select2-component/lib/select2-interfaces';
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
    ApiResultSolarPanelConfiguration,
    CalculationResponse,
    CompanyResponse,
    GetCalculationResponse,
    Module,
    ResultResponse,
    TenantResponse,
} from '../../domain/api-result';
import {
    ActiveTabService,
    FinancingType,
} from '../../services/active-tab.service';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { AddressErrorModalComponent } from '../../shared/components/address-error-modal/address-error-modal.component';
import { LoadingComponent } from '../../shared/components/loading/loading.component';
import { BoxComponent } from './components/box/box.component';
import { CalculationShareModalComponent } from './components/calculation-share-modal/calculation-share-modal.component';
import { PhoneModalComponent } from './components/phone-modal/phone-modal.component';
import { ResultsAsideComponent } from './components/results-aside/results-aside.component';
import { ComponentsSelectComponent } from './page-components/components-select/components-select.component';
import { ConceptComponent } from './page-components/concept/concept.component';
import { EnergyStorageComponent } from './page-components/energy-storage/energy-storage.component';
import { OfferComponent } from './page-components/offer/offer.component';
import {
    CalculatePricesService,
    CalculationInfoData,
} from './services/calculate-prices.service';
import { ModalService } from './services/modal.service';
import { ModulesService } from './services/modules.service';

interface ResultResourceRequest {
    currentIndex: number;
    jobId: string | undefined;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslateModule,
        CalculationShareModalComponent,
        AddressErrorModalComponent,
        ResultsAsideComponent,
        PhoneModalComponent,
        DecimalPipe,
        CurrencyPipe,
        BoxComponent,
        ConceptComponent,
        LoadingComponent,
        ComponentsSelectComponent,
        EnergyStorageComponent,
        OfferComponent,
    ],
    selector: 'app-results',
    standalone: true,
    styleUrl: './results.component.scss',
    templateUrl: './results.component.html',
})
export class ResultsComponent implements OnDestroy {
    // private signals
    private jobId: WritableSignal<string | undefined> = signal(undefined);
    private routeParams: Signal<Params | undefined> = toSignal(
        this.activatedRoute.params,
    );
    private routeQueryParams: Signal<Params | undefined> = toSignal(
        this.activatedRoute.queryParams,
    );

    // signals
    protected calculationId: WritableSignal<string | undefined> =
        signal(undefined);
    protected currentIndex: WritableSignal<number> = signal(-1);
    protected financingType: WritableSignal<FinancingType> = signal('cash');
    protected selectedSolarPanel: WritableSignal<Select2Option | undefined> =
        signal(undefined);
    protected selectedInverter: WritableSignal<Select2Option | undefined> =
        signal(undefined);
    protected selectedBattery: WritableSignal<Select2Option | undefined> =
        signal(undefined);
    protected batteryCount: WritableSignal<number> = signal(0);
    protected batteryEnabled: WritableSignal<boolean> = signal(false);
    protected energyStorageSliderValue: WritableSignal<number> = signal(0);
    protected chargingCarSliderValue: WritableSignal<number> = signal(0);

    // computed signals
    protected isReadonly: Signal<boolean> = computed((): boolean => {
        return undefined !== this.routeParams()?.['calculationId'];
    });

    protected isLoading: Signal<boolean> = computed((): boolean => {
        return (
            this.tenantResource.isLoading() &&
            this.calculationResource.isLoading() &&
            this.modulesResource.isLoading() &&
            this.resultResource.isLoading() &&
            this.companyResource.isLoading()
        );
    });

    protected address: Signal<string> = computed((): string => {
        return (
            this.resultResource.value()?.solarPanelConfiguration?.address ?? ''
        );
    });

    protected systemPowerDcKwh: Signal<number> = computed((): number => {
        return (
            this.resultResource.value()?.solarPanelConfiguration
                ?.systemPowerDcKwh ?? 0
        );
    });

    protected oldBillMonthly: Signal<number> = computed((): number => {
        return (
            this.resultResource.value()?.solarPanelConfiguration
                ?.oldBillMonthly ?? 0
        );
    });

    protected newBillMonthly: Signal<number> = computed((): number => {
        const newBill: number | undefined =
            this.resultResource.value()?.solarPanelConfiguration
                ?.newBillMonthly;

        if (undefined === newBill || 0 > newBill) {
            return 0;
        }

        return newBill;
    });

    // resources
    protected tenantResource: ResourceRef<TenantResponse | undefined> =
        rxResource({
            request: this.routeParams,
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<TenantResponse> => {
                return this.sunnaApiService.tenant(
                    params.request?.['companySlug'] ??
                        environment.DEFAULT_COMPANY_SLUG,
                );
            },
        });

    protected calculationResource: ResourceRef<
        GetCalculationResponse | undefined
    > = rxResource({
        request: this.routeParams,
        loader: (
            params: ResourceLoaderParams<Params | undefined>,
        ): Observable<GetCalculationResponse | undefined> => {
            const calculationId: string | undefined =
                params.request?.['calculationId'] ??
                this.routeQueryParams()?.['calculationId'] ??
                undefined;

            this.calculationId.set(calculationId);

            if (undefined === calculationId) {
                return of(undefined);
            }

            return this.sunnaApiService.calculation(calculationId);
        },
    });

    protected modulesResource: ResourceRef<Module[] | undefined> = resource({
        request: toSignal(this.activatedRoute.params),
        loader: (
            params: ResourceLoaderParams<Params | undefined>,
        ): Promise<Module[]> => {
            return this.modulesService.fetchModules(
                params.request?.['companySlug'],
            );
        },
    });

    protected resultResource: ResourceRef<ResultResponse | undefined> =
        rxResource({
            request: (): ResultResourceRequest => ({
                currentIndex: this.currentIndex(),
                jobId: this.jobId(),
            }),
            loader: (
                params: ResourceLoaderParams<ResultResourceRequest>,
            ): Observable<ResultResponse | undefined> => {
                if (
                    -1 === params.request.currentIndex ||
                    undefined === params.request.jobId
                ) {
                    return of(undefined);
                }

                return this.sunnaApiService.result(
                    params.request.jobId,
                    params.request.currentIndex,
                );
            },
        });

    protected companyResource: ResourceRef<CompanyResponse | undefined> =
        rxResource({
            request: toSignal(this.activatedRoute.params),
            loader: (
                params: ResourceLoaderParams<Params | undefined>,
            ): Observable<CompanyResponse> => {
                return this.sunnaApiService.company(
                    params.request?.['companySlug'],
                );
            },
        });

    private subscription: Subscription = new Subscription();

    constructor(
        private readonly sunnaApiService: SunnaApiService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly modulesService: ModulesService,
        private readonly calculatePricesService: CalculatePricesService,
        private readonly activeTabService: ActiveTabService,
        readonly languageService: LanguageService,
        readonly configService: ConfigService,
    ) {
        effect((): void => {
            // reload calculation on any signal change
            this.jobId();
            this.currentIndex();
            this.financingType();
            this.selectedSolarPanel();
            this.selectedInverter();
            this.selectedBattery();
            this.batteryCount();
            this.batteryEnabled();

            this.reloadCalculation();
        });

        effect((): void => {
            const calculation: GetCalculationResponse | undefined =
                this.calculationResource.value();

            if (this.calculationResource.isLoading()) {
                return;
            }

            if (undefined === calculation) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});

                return;
            }

            this.onCalculation(calculation);

            if (!this.jobId() || 0 > this.currentIndex()) {
                this.modalService.setAddressErrorModalState(true);
                return;
            }
        });

        effect((): void => {
            const tenant: TenantResponse | undefined =
                this.tenantResource.value();

            if (this.tenantResource.isLoading()) {
                return;
            }

            if (undefined === tenant) {
                this.router
                    .navigate(['404'], {
                        skipLocationChange: true,
                        replaceUrl: false,
                    })
                    .then((): void => {});
                return;
            }

            languageService.setLanguageOptions(tenant);
            configService.setColorsFromTenant(tenant);
        });

        this.subscription.add(
            this.activeTabService.activeTab.subscribe(
                (activeTab: FinancingType): void => {
                    this.financingType.set(activeTab);
                },
            ),
        );
    }

    private onCalculation(calculationResponse: GetCalculationResponse): void {
        this.jobId.set(calculationResponse.job_id as string);
        if (-1 === this.currentIndex()) {
            this.currentIndex.set(
                calculationResponse.configuration_index as number,
            );
        }

        this.batteryEnabled.set(calculationResponse.has_battery);

        if (null === calculationResponse.system_battery_qty) {
            this.batteryCount.set(1);
            return;
        }

        this.batteryCount.set(calculationResponse.system_battery_qty);
    }

    private reloadCalculation(): void {
        const data: CalculationInfoData = {
            apiResultSolarPanelConfiguration:
                this.resultResource.value()?.solarPanelConfiguration,
            batteryCount: this.batteryCount(),
            batteryEnabled: this.batteryEnabled(),
            company: this.companyResource.value(),
            modules: this.modulesResource.value(),
            selectedBatteryTypeValue: this.selectedBattery()?.value,
            selectedInverterValue: this.selectedInverter()?.value,
            selectedSolarPanelValue: this.selectedSolarPanel()?.value,
        };

        this.calculatePricesService.forceRecalculate(data);
    }

    protected changePanelsCount(count: number): void {
        const solarPanelConfig: ApiResultSolarPanelConfiguration =
            this.resultResource.value()
                ?.solarPanelConfiguration as ApiResultSolarPanelConfiguration;

        const panelsCount: number = solarPanelConfig.totalPanelCount;

        let currIndex: number = this.currentIndex();

        if (count > panelsCount) {
            currIndex += 1;
        } else {
            currIndex -= 1;
        }

        if (1 >= currIndex) {
            currIndex = 1;
        }

        this.currentIndex.set(currIndex);
    }

    private getStringValue(item: Select2Option | undefined): string {
        if (
            undefined === item ||
            'object' === typeof item.value ||
            'boolean' === typeof item.value
        ) {
            return '';
        }

        return item.value?.toString() ?? '';
    }

    protected submitModal(phoneNumber: string): void {
        const sunnaSlug: string = this.tenantResource.value()?.slug as string;
        const calculationId: string = this.calculationResource.value()
            ?.id as string;

        lastValueFrom(
            this.sunnaApiService.saveCalculation(calculationId, {
                panel_number:
                    this.resultResource.value()?.solarPanelConfiguration
                        ?.totalPanelCount ?? 0,
                configuration_index: this.currentIndex(),
                job_id: this.jobId(),
                phone_number: phoneNumber,
                sunna_slug: sunnaSlug,
                system_panel_module: this.getStringValue(
                    this.selectedSolarPanel(),
                ),
                system_inverter_module: this.getStringValue(
                    this.selectedInverter(),
                ),
                has_battery: this.batteryEnabled(),
                system_battery_module: this.getStringValue(
                    this.selectedBattery(),
                ),
                system_battery_qty: this.batteryCount(),
                financial_type: this.financingType(),
                consumption_energy_storage: this.energyStorageSliderValue(),
                consumption_energy_car_charger: this.chargingCarSliderValue(),
            }),
        )
            .then((result: CalculationResponse): void => {
                this.router
                    .navigate([sunnaSlug, 'calculation', result.calculation_id])
                    .then((): void => {});

                localStorage.setItem(
                    'previousCalculation',
                    JSON.stringify({
                        company: sunnaSlug,
                        power:
                            this.resultResource.value()?.solarPanelConfiguration
                                ?.systemPowerDcKwh ?? 0,
                        address:
                            this.resultResource.value()?.solarPanelConfiguration
                                ?.address ?? '',
                        calculationId: result.calculation_id,
                    }),
                );
            })
            .catch((): void => {
                this.modalService.setAddressErrorModalState(true);
            });
    }

    protected share(): void {
        const sunnaSlug: string = this.tenantResource.value()?.slug as string;
        const calculationId: string = this.calculationResource.value()
            ?.id as string;

        this.modalService.setCalculationShareModalState(true, {
            sunnaSlug: sunnaSlug,
            calculationId: calculationId,
        });
    }

    public ngOnDestroy(): void {
        this.tenantResource.destroy();
        this.calculationResource.destroy();
        this.modulesResource.destroy();
        this.resultResource.destroy();
        this.subscription.unsubscribe();
    }
}
