import { Component, inject } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

import { ColorsService } from '../../../calculations/services/colors.service';

@Component({
    selector: 'app-image-placeholder',
    imports: [TranslatePipe],
    templateUrl: './image-placeholder.component.html',
    styleUrl: './image-placeholder.component.scss',
})
export class ImagePlaceholderComponent {
    protected readonly colorsService: ColorsService = inject(ColorsService);
}
