import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    input,
    InputSignal,
    output,
    OutputEmitterRef,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../../../../../environments/environment';
import {
    PanelCoordinates,
    ResultResponse,
    TenantResponse,
} from '../../../../domain/api-result';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { CountInputComponent } from '../../components/count-input/count-input.component';
import { ImagePanelsComponent } from '../../components/image-panels/image-panels.component';
import { ImagePlaceholderComponent } from '../../components/image-placeholder/image-placeholder.component';
import { ProgressBarComponent } from '../../components/progress-bar/progress-bar.component';

@Component({
    selector: 'app-concept',
    standalone: true,
    imports: [
        CountInputComponent,
        ProgressBarComponent,
        TranslateModule,
        ImagePanelsComponent,
        CurrencyPipe,
        DecimalPipe,
        LoadingComponent,
        ImagePlaceholderComponent,
    ],
    templateUrl: './concept.component.html',
    styleUrl: './concept.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptComponent {
    public isLoading: InputSignal<boolean> = input(false);
    public result: InputSignal<ResultResponse | undefined> = input();
    public tenant: InputSignal<TenantResponse | undefined> = input();
    public isReadonly: InputSignal<boolean> = input(false);

    private readonly DEFAULT_ENERGY_PRICE_KWH: number = 0.693;

    public changePanelsCountEvent: OutputEmitterRef<number> = output<number>();

    protected readonly imageError: WritableSignal<boolean> = signal(false);

    protected image: Signal<string | undefined> = computed(
        (): string | undefined => {
            let imageUri: string | undefined = this.result()?.imageUrl;

            if (undefined === imageUri) {
                return undefined;
            }

            if ('/' === imageUri.charAt(0)) {
                imageUri = imageUri.substring(1);
            }

            return `${environment.API_URL}${imageUri}`;
        },
    );

    protected panelsCount: Signal<number> = computed((): number => {
        return this.result()?.solarPanelConfiguration?.totalPanelCount ?? 0;
    });

    protected energyOffsetPercent: Signal<number> = computed((): number => {
        return this.result()?.solarPanelConfiguration?.energyOffsetPercent ?? 0;
    });

    protected yearlyEnergyDcKwh: Signal<number> = computed((): number => {
        return this.result()?.solarPanelConfiguration?.yearlyEnergyDcKwh ?? 0;
    });

    protected systemPower: Signal<number> = computed((): number => {
        return this.result()?.solarPanelConfiguration?.systemPowerDcKwh ?? 0;
    });

    protected consumption: Signal<string> = computed((): string => {
        const energyPrice: number =
            this.tenant()?.preferences.energy_price_for_kwh ??
            this.DEFAULT_ENERGY_PRICE_KWH;

        return ((this.oldBillMonthly() / energyPrice) * 12).toFixed(2);
    });

    protected hasConsumption: Signal<boolean> = computed(
        (): boolean => parseFloat(this.consumption()) > 0,
    );

    protected oldBillMonthly: Signal<number> = computed((): number => {
        return this.result()?.solarPanelConfiguration?.oldBillMonthly ?? 0;
    });

    protected newBillMonthly: Signal<number> = computed((): number => {
        const newBill: number | undefined =
            this.result()?.solarPanelConfiguration?.newBillMonthly;

        if (undefined === newBill || 0 > newBill) {
            return 0;
        }

        return newBill;
    });

    protected currency: Signal<string> = computed((): string => {
        return this.tenant()?.currency_code ?? 'USD';
    });

    protected panelCoordinatePoints: Signal<PanelCoordinates[]> = computed(
        (): PanelCoordinates[] => {
            return (
                this.result()?.solarPanelConfiguration?.panelCoordinatePoints ??
                []
            );
        },
    );

    protected changePanelsCount(count: number): void {
        this.changePanelsCountEvent.emit(count);
    }
}
