import { effect, Injectable, signal, WritableSignal } from '@angular/core';

import { TenantResponse } from '../domain/api-result';

export interface ColorComponents {
    r: number;
    g: number;
    b: number;
    alpha: number;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    public advancedEnabled: WritableSignal<boolean> = signal(false);

    // main
    public mainColor: WritableSignal<string> = signal('#EFDECE');
    public buttonColor: WritableSignal<string> = signal('#403B46');
    public backgroundOpacity: WritableSignal<number> = signal(0.12);
    public companyLogo: WritableSignal<string | null> = signal(null);

    // advanced
    public backgroundColor: WritableSignal<string> = signal('#FCF8F5');
    public logoWidth: WritableSignal<number> = signal(54);
    public hideLogo: WritableSignal<boolean> = signal(false);
    public hideName: WritableSignal<boolean> = signal(false);

    constructor() {
        effect((): void => {
            const mainColor: string = this.mainColor();
            const buttonColor: string = this.buttonColor();
            const backgroundColor: string = this.backgroundColor();
            const backgroundOpacity: number = this.backgroundOpacity();

            if (this.advancedEnabled()) {
                this.advancedMode(mainColor, buttonColor, backgroundColor);
                return;
            }

            this.normalMode(mainColor, buttonColor, backgroundOpacity);
        });
    }

    public setColorsFromTenant(tenant: TenantResponse): void {
        this.advancedEnabled.set(tenant.brand.appearance_advanced_enabled);

        // main
        this.mainColor.set(tenant.brand.appearance_colors_main);
        this.buttonColor.set(tenant.brand.appearance_colors_button);
        this.backgroundOpacity.set(tenant.brand.appearance_opacity_background);
        this.companyLogo.set(tenant.brand.logo);

        // advanced
        if (!tenant.brand.appearance_advanced_enabled) {
            return;
        }
        this.backgroundColor.set(tenant.brand.appearance_colors_background);
        this.logoWidth.set(tenant.brand.appearance_logo_width);
        this.hideLogo.set(tenant.brand.has_hidden_logo);
        this.hideName.set(tenant.brand.has_hidden_name);
    }

    private normalMode(
        mainColor: string,
        buttonColor: string,
        backgroundOpacity: number,
    ): void {
        const backgroundColor: string = this.hexWithAlphaToHex(
            `${mainColor}${this.componentToHex((backgroundOpacity * 255) | (1 << 8)).slice(1)}`,
        ).slice(0, 7);

        this.setCss(mainColor, buttonColor, backgroundColor);
    }

    private advancedMode(
        mainColor: string,
        buttonColor: string,
        backgroundColor: string,
    ): void {
        this.setCss(mainColor, buttonColor, backgroundColor);
    }

    private setCss(
        mainColor: string,
        buttonColor: string,
        backgroundColor: string,
    ): void {
        const root: HTMLElement | null = document.querySelector(':root');

        root?.style.setProperty('--main-color', mainColor);
        root?.style.setProperty(
            '--main-text-color',
            this.calcTextColor(mainColor),
        );

        root?.style.setProperty('--background-color', backgroundColor);
        root?.style.setProperty(
            '--background-text-color',
            this.calcTextColor(backgroundColor),
        );

        root?.style.setProperty('--button-color', buttonColor);
        root?.style.setProperty(
            '--button-text-color',
            this.calcTextColor(buttonColor),
        );
    }

    private hexWithAlphaToHex(value: string): string {
        const colorComponent: ColorComponents = this.hex2rgb(value);

        const alpha: number = 1 - colorComponent.alpha;
        colorComponent.r = Math.round(
            (colorComponent.alpha * (colorComponent.r / 255) + alpha) * 255,
        );
        colorComponent.g = Math.round(
            (colorComponent.alpha * (colorComponent.g / 255) + alpha) * 255,
        );
        colorComponent.b = Math.round(
            (colorComponent.alpha * (colorComponent.b / 255) + alpha) * 255,
        );
        colorComponent.alpha = 1;

        return this.rgb2hex(colorComponent);
    }

    public hex2rgb(hex: string): ColorComponents {
        return {
            r: parseInt(hex.slice(1, 3), 16),
            g: parseInt(hex.slice(3, 5), 16),
            b: parseInt(hex.slice(5, 7), 16),
            alpha: hex.slice(7, 9) ? parseInt(hex.slice(7, 9), 16) / 255 : 1,
        };
    }

    public rgb2hex(rgb: ColorComponents): string {
        return `#${this.componentToHex(rgb.r)}${this.componentToHex(rgb.g)}${this.componentToHex(rgb.b)}${this.componentToHex(Math.floor(rgb.alpha * 255))}`;
    }

    private componentToHex(c: number): string {
        const hex: string = c.toString(16);
        return (hex.length == 1 ? 0 + hex : hex).toUpperCase();
    }

    private calcTextColor(hex: string): string {
        const contrast1: number = this.contrast(hex, '#403B46');
        const contrast2: number = this.contrast(hex, '#FFFFFF');

        return contrast1 >= contrast2 ? '#403B46' : '#FFFFFF';
    }

    private luminance(r: number, g: number, b: number, alpha: number): number {
        r = 255 - alpha * (255 - r);
        g = 255 - alpha * (255 - g);
        b = 255 - alpha * (255 - b);

        const a: number[] = [r, g, b].map((v: number): number => {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });

        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    private contrast(rgb1: string, rgb2: string): number {
        const {
            r: r1,
            g: g1,
            b: b1,
            alpha: alpha1,
        }: ColorComponents = this.hex2rgb(rgb1);
        const {
            r: r2,
            g: g2,
            b: b2,
            alpha: alpha2,
        }: ColorComponents = this.hex2rgb(rgb2);

        const lum1: number = this.luminance(r1, g1, b1, alpha1);
        const lum2: number = this.luminance(r2, g2, b2, alpha2);
        const brightest: number = Math.max(lum1, lum2);
        const darkest: number = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    }
}
