<main>
    <app-calculations-aside [calculationStep]="calculationStep()" />
    <div class="content">
        <svg xmlns="http://www.w3.org/2000/svg" width="1024" height="768" viewBox="0 0 1024 768" fill="none">
            <path id="lighter1MainColor" d="M273.352 484.545C277.062 477.759 279.175 469.979 279.175 461.697C279.175 451.618 276.03 442.281 270.696 434.581C281.91 428.384 289.506 416.444 289.506 402.715C289.506 386.021 278.265 371.988 262.954 367.704C268.747 359.807 272.208 350.096 272.208 339.549C272.208 313.222 250.894 291.882 224.601 291.882C224.04 291.882 223.495 291.949 222.94 291.965C224.017 288.527 224.601 284.868 224.601 281.073C224.601 271.742 221.065 263.262 215.298 256.821C216.253 252.927 216.818 248.878 216.818 244.689C216.818 216.692 194.15 194 166.189 194C138.232 194 115.566 216.692 115.566 244.689C115.566 253.207 117.689 261.215 121.396 268.26C106.616 272.604 95.7699 286.079 95.3404 302.18C85.6212 308.712 79.2235 319.81 79.2235 332.409C79.2235 339.296 81.169 345.708 84.4874 351.2C81.1446 357.718 79.2235 365.092 79.2235 372.921C79.2235 373.492 79.2902 374.048 79.3113 374.615C59.0464 380.092 44.1154 398.568 44.1154 420.587C44.1154 444.644 61.9338 464.482 85.0632 467.733C82.8705 472.417 81.6082 477.625 81.6082 483.143C81.6082 484.56 81.709 485.954 81.8652 487.327C81.779 487.327 81.696 487.314 81.6082 487.314C55.3149 487.314 33.9991 508.655 33.9991 534.98C33.9991 561.304 55.3149 582.645 81.6082 582.645C93.6194 582.645 104.56 578.157 112.939 570.807C128.664 586.585 150.411 596.35 174.435 596.35C196.663 596.35 216.928 587.987 232.294 574.243C240.578 580.253 250.732 583.841 261.743 583.841C289.518 583.841 312.032 561.299 312.032 533.49C312.034 509.691 295.52 489.796 273.352 484.545Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path id="darkerMainColor" d="M164.913 815V265.49" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M145.726 329.28L165.588 349.166L188.571 326.153" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M109.483 497.932H137.573V465.389" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M145.726 410.83L165.588 430.712L208.32 387.928" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M67.4533 425.967L165.586 524.215L242.74 446.969" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M84.9149 564.46L165.696 645.338L261.318 543.321" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M126.086 543.321V604.899" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path d="M232.308 543.321L238.87 567.271" [attr.stroke]="colorsService.darkerMainColor()" stroke-miterlimit="10"/>
            <path id="lighter2MainColor" d="M197.815 553.739V596.141H521.316V665.919H197.815V793.037H918.362V553.739H197.815Z" [attr.fill]="colorsService.lighter2MainColor()"/>
            <path d="M876.202 132.572L442.832 132.458L152.862 511.497L917.879 512.893L1205.96 132.674L968.853 132.945" [attr.fill]="colorsService.lighter2MainColor()"/>
            <path d="M876.202 132.572L442.832 132.458L152.862 511.497L917.879 512.893L1205.96 132.674L968.853 132.945" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path id="mainColor" d="M942.102 523.476L1205.96 170.216V132.674" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M152.862 512.359V555.578L917.879 555.152V512.893" [attr.fill]="colorsService.lighter2MainColor()"/>
            <path d="M152.862 512.359V555.578L917.879 555.152V512.893" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M942.102 791.689V523.476" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M197.815 791.69V665.919" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M197.815 596.141V553.739" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M215.757 596.14V665.918" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M413.871 596.14V665.918" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M197.815 596.14H521.316V665.918H197.815" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M918.361 793.038V555.915" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M928.92 78.6682V185.959L968.852 135.25V78.6682H928.92Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path d="M723.486 596.14H701.698V790.859H723.486V596.14Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path d="M918.363 555.564H197.815V566.087H918.363V555.564Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path d="M876.204 78.6682V185.816L928.921 185.959L968.854 135.25V78.6682H928.633H876.204Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M928.92 185.816V78.6682" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M871.318 68.8828H928.921H974.218V78.6683H928.921H871.318V68.8828Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M827.886 596.14H701.698V790.859H827.886V596.14Z" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M1365 657.256H942.102" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M1364.12 523.654H942.102" [attr.stroke]="colorsService.mainColor()" stroke-miterlimit="10"/>
            <path d="M472.245 322.309L370.466 454.669H359.075L472.552 307.681H598.321L587.484 322.235L472.245 322.309Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path d="M359.075 454.669H485.747L598.321 307.681H472.552L359.075 454.669Z" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M524.673 322.235L422.937 454.67" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M802.277 322.309L700.496 454.669H689.105L802.586 307.681H928.356L917.519 322.235L802.277 322.309Z" [attr.fill]="colorsService.lighter1MainColor()"/>
            <path d="M689.105 454.669H815.781L928.356 307.681H802.586L689.105 454.669Z" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>
            <path d="M854.706 322.235L752.967 454.67" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5" stroke-miterlimit="10"/>

            <g [@fade]="stepsService.displayStep1() ? 'in' : 'out'">
                <path class="step-1-animation" opacity="0.4" d="M1190.15 217.415H340L381.746 162.623H1231.89L1190.15 217.415Z" fill="url(#gradient0)"/>
                <path class="step-1-animation" d="M381.746 159H1231.89" stroke="#44B2E5" stroke-miterlimit="10"/>
                <path class="step-1-animation" opacity="0.6" d="M381.746 159H1231.89" stroke="#AFE1F6" stroke-width="3" stroke-miterlimit="10"/>

                <path d="M876.204 540.949H934.118L968.854 495.425" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M125.72 490.901L87.5322 540.95H155.488" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M484.715 102.799H421.861L391.299 142.853" stroke="#44B2E5" stroke-width="2" stroke-miterlimit="10"/>
            </g>

            <g [@fade]="stepsService.displayStep2() ? 'in' : 'out'">
                <g opacity="0.6" filter="url(#filter0)">
                    <path d="M1207.28 132.242H442.26L152.862 511.505H917.879L1207.28 132.242Z"
                          fill="url(#gradient1)"/>
                </g>
                <g opacity="0.4">
                    <path d="M1207.28 132.242H442.26L152.862 511.505H917.879L1207.28 132.242Z" fill="#FFDB5E"/>
                    <path d="M1207.28 132.242H442.26L152.862 511.505H917.879L1207.28 132.242Z"
                          fill="url(#gradient2)"/>
                </g>
            </g>

            <g [@fade]="stepsService.displayStep3() ? 'in' : 'out'">
                <path [class.step-3-animation-3]="stepsService.displayStep3()" opacity="0.2" d="M1207.28 132.241H442.26L152.862 511.504H917.879L1207.28 132.241Z" fill="#8CC640"/>

                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M347.526 511.504L637.6 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M410.857 511.504L700.931 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M474.188 511.504L764.262 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M537.519 511.504L827.593 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M600.85 511.504L890.924 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M664.18 511.504L954.254 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M727.511 511.504L1017.59 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M790.842 511.504L1080.92 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M854.173 511.504L1144.25 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M284.195 511.504L574.269 132.241" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-2]="stepsService.displayStep3()" d="M220.534 511.504L510.608 132.241" stroke="#8CC640" stroke-miterlimit="10"/>

                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M421.005 161.48H1184.85" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M398.598 190.719H1162.15" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M375.56 219.961H1140.03" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M353.778 249.2H1118.48" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M331.101 278.441H1095.5" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M308.962 307.68H1074.03" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M286.12 336.92H1051.83" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M264.142 366.159H1029.71" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M241.346 395.4H1007.01" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M218.894 424.639H985.751" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M197.348 453.878H963.343" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M174.239 483.119H941.117" stroke="#8CC640" stroke-miterlimit="10"/>
                <path [class.step-3-animation-1]="stepsService.displayStep3()" d="M1207.28 132.241H442.26L152.862 511.504H917.879L1207.28 132.241Z" stroke="#8CC640" stroke-miterlimit="10"/>
            </g>

            <g [@fade]="stepsService.displayStep4() ? 'in' : 'out'">
                <g [class.step-4-animation]="stepsService.displayStep4()">
                    <path opacity="0.2" d="M600.248 307.681H473.233L362.999 453.879H490.012L600.248 307.681Z" fill="#EE285B"/>
                    <path d="M600.248 307.681H473.233L362.999 453.879H490.012L600.248 307.681Z" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M385.363 424.64H512.025" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M407.913 395.401H534.575" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M430.323 366.16H556.985" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M453.021 336.92H579.683" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M538.15 307.681L426.33 453.879" stroke="#EC2046" stroke-miterlimit="10"/>
                </g>

                <g [class.step-4-animation]="stepsService.displayStep4()" style="animation-delay: 1500ms">
                    <path opacity="0.2" d="M926.355 307.681H799.34L689.105 453.879H816.119L926.355 307.681Z" fill="#EE285B"/>
                    <path d="M926.355 307.681H799.34L689.105 453.879H816.119L926.355 307.681Z" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M711.47 424.64H838.131" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M734.02 395.401H860.683" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M756.43 366.16H883.092" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M779.128 336.92H905.791" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M864.253 307.681L752.436 453.879" stroke="#EC2046" stroke-miterlimit="10"/>
                </g>

                <g [class.step-4-animation]="stepsService.displayStep4()" style="animation-delay: 3000ms">
                    <path opacity="0.2" d="M928.921 78.668H876.204V185.814H928.921V78.668Z" fill="#EC2046"/>
                    <path opacity="0.2" d="M928.92 78.668H968.852V136.202L928.92 185.815V78.668Z" fill="#EC2046"/>
                    <path opacity="0.2" d="M974.216 68.8827H871.318V78.6682H974.216V68.8827Z" fill="#EC2046"/>
                    <path d="M928.921 78.668H876.204V185.814H928.921V78.668Z" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M928.92 78.668H968.852V136.202L928.92 185.815V78.668Z" stroke="#EC2046" stroke-miterlimit="2"/>
                    <path d="M974.216 68.8827H871.318V78.6682H974.216V68.8827Z" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M876.204 166.466H928.921" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M876.204 143.648H928.921" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M876.204 120.835H928.921" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M876.204 98.0188H928.921" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M928.923 166.465L968.854 119.707" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M928.923 143.648L968.854 96.8911" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M928.923 120.834L964.783 78.844" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M928.923 98.0181L945.297 78.844" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M902.562 78.668V185.959" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M943.188 78.668V168.008" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path d="M956.904 78.668V151.302" stroke="#EC2046" stroke-miterlimit="10"/>
                    <path opacity="0.2" d="M968.852 136.491L928.92 185.815V78.668H968.852V136.491Z" fill="#EC2046"/>
                </g>
            </g>

            <g [@fade]="stepsService.displayStep5() ? 'in' : 'out'">
                <g [class.step-5-animation]="stepsService.displayStep5()">
                    <g filter="url(#filter1)">
                        <path d="M549.758 142.597H452.888L363.711 258.534H460.581L549.758 142.597Z" fill="white"/>
                        <path d="M365.742 257.534L453.38 143.597H547.727L460.089 257.534H365.742Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M379 241L472 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M392 224L486 224" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M404 208L498 208" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M416 192L511 192" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M429 176L523 176" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M441 159L536 159" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M388 256.5L474 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M413 256.5L499 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M438 256.5L524 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 250ms">
                    <g filter="url(#filter2)">
                        <path d="M458.047 264H361.177L272 379.937H368.87L458.047 264Z" fill="white"/>
                        <path d="M274.031 378.937L361.669 265H456.016L368.378 378.937H274.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M287.289 362.403L380.289 362.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M300.289 345.403L394.289 345.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M312.289 329.403L406.289 329.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M324.289 313.403L419.289 313.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M337.289 297.403L431.289 297.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M349.289 280.403L444.289 280.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M296.289 377.903L382.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M321.289 377.903L407.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M346.289 377.903L432.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 500ms">
                    <g filter="url(#filter3)">
                        <path d="M365.047 385H268.177L179 500.937H275.87L365.047 385Z" fill="white"/>
                        <path d="M181.031 499.937L268.669 386H363.016L275.378 499.937H181.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M194.289 483.403L287.289 483.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M207.289 466.403L301.289 466.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M219.289 450.403L313.289 450.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M231.289 434.403L326.289 434.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M244.289 418.403L338.289 418.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M256.289 401.403L351.289 401.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M203.289 498.903L289.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M228.289 498.903L314.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M253.289 498.903L339.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 750ms">
                    <g filter="url(#filter4)">
                        <path d="M866.758 142.597H769.888L680.711 258.534H777.581L866.758 142.597Z" fill="white"/>
                        <path d="M682.742 257.534L770.38 143.597H864.727L777.089 257.534H682.742Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M696 241L789 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M709 224L803 224" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M721 208L815 208" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M733 192L828 192" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M746 176L840 176" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M758 159L853 159" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M705 256.5L791 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M730 256.5L816 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M755 256.5L841 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 1000ms">
                    <g filter="url(#filter5)">
                        <path d="M775.047 264H678.177L589 379.937H685.87L775.047 264Z" fill="white"/>
                        <path d="M591.031 378.937L678.669 265H773.016L685.378 378.937H591.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M604.289 362.403L697.289 362.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M617.289 345.403L711.289 345.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M629.289 329.403L723.289 329.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M641.289 313.403L736.289 313.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M654.289 297.403L748.289 297.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M666.289 280.403L761.289 280.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M613.289 377.903L699.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M638.289 377.903L724.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M663.289 377.903L749.289 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 1250ms">
                    <g filter="url(#filter6)">
                        <path d="M682.047 385H585.177L496 500.937H592.87L682.047 385Z" fill="white"/>
                        <path d="M498.031 499.937L585.669 386H680.016L592.378 499.937H498.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M511.289 483.403L604.289 483.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M524.289 466.403L618.289 466.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M536.289 450.403L630.289 450.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M548.289 434.403L643.289 434.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M561.289 418.403L655.289 418.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M573.289 401.403L668.289 401.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M520.289 498.903L606.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M545.289 498.903L631.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M570.289 498.903L656.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 1500ms">
                    <g filter="url(#filter7)">
                        <path d="M1183.76 142.597H1086.89L997.711 258.534H1094.58L1183.76 142.597Z" fill="white"/>
                        <path d="M999.742 257.534L1087.38 143.597H1181.73L1094.09 257.534H999.742Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M1013 241L1106 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M1022 256.5L1108 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 1750ms">
                    <g filter="url(#filter8)">
                        <path d="M1092.05 264H995.177L906 379.937H1002.87L1092.05 264Z" fill="white"/>
                        <path d="M908.031 378.937L995.669 265H1090.02L1002.38 378.937H908.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M921.289 362.403L1014.29 362.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M934.289 345.403L1028.29 345.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M946.289 329.403L1040.29 329.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M958.289 313.403L1053.29 313.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M971.289 297.403L1065.29 297.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M983.289 280.403L1078.29 280.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M930.289 377.903L1016.29 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M955.289 377.903L1041.29 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M980.289 377.903L1066.29 265.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 2000ms">
                    <g filter="url(#filter9)">
                        <path d="M999.047 385H902.177L813 500.937H909.87L999.047 385Z" fill="white"/>
                        <path d="M815.031 499.937L902.669 386H997.016L909.378 499.937H815.031Z" [attr.stroke]="colorsService.mainColor()" stroke-width="2"/>
                    </g>
                    <path d="M828.289 483.403L921.289 483.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M841.289 466.403L935.289 466.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M853.289 450.403L947.289 450.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M865.289 434.403L960.289 434.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M878.289 418.403L972.289 418.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M890.289 401.403L985.289 401.403" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M837.289 498.903L923.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M862.289 498.903L948.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M887.289 498.903L973.289 386.903" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M471.488 257.534L559.126 143.597H653.473L565.836 257.534H471.488Z" fill="white" [attr.stroke]="colorsService.mainColor()"
                          stroke-width="2"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 2250ms">
                    <g filter="url(#filter10)">
                        <path d="M655.047 142.597H558.177L469 258.534H565.87L655.047 142.597Z" fill="white"/>
                        <path d="M471.031 257.534L558.669 143.597H653.016L565.378 257.534H471.031Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M484.289 241L577.289 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M497.289 224L591.289 224" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M509.289 208L603.289 208" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M521.289 192L616.289 192" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M534.289 176L628.289 176" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M546.289 159L641.289 159" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M493.289 256.5L579.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M518.289 256.5L604.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M543.289 256.5L629.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 2500ms">
                    <g filter="url(#filter11)">
                        <path d="M761.047 142.597H664.177L575 258.534H671.87L761.047 142.597Z" fill="white"/>
                        <path d="M577.031 257.534L664.669 143.597H759.016L671.378 257.534H577.031Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M590.289 241L683.289 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M603.289 224L697.289 224" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M615.289 208L709.289 208" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M627.289 192L722.289 192" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M640.289 176L734.289 176" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M652.289 159L747.289 159" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M599.289 256.5L685.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M624.289 256.5L710.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M649.289 256.5L735.289 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>

                <g [class.step-5-animation]="stepsService.displayStep5()" style="animation-delay: 2750ms">
                    <g filter="url(#filter12)">
                        <path d="M1076.05 142.597H979.177L890 258.534H986.87L1076.05 142.597Z" fill="white"/>
                        <path d="M892.031 257.534L979.669 143.597H1074.02L986.378 257.534H892.031Z" [attr.stroke]="colorsService.mainColor()"
                              stroke-width="2"/>
                    </g>
                    <path d="M905.289 241L998.289 241" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M918.289 224L1012.29 224" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M930.289 208L1024.29 208" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M942.289 192L1037.29 192" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M955.289 176L1049.29 176" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M967.289 159L1062.29 159" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M914.289 256.5L1000.29 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M939.289 256.5L1025.29 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                    <path d="M964.289 256.5L1050.29 144.5" [attr.stroke]="colorsService.mainColor()" stroke-width="1.5"/>
                </g>
            </g>

            <g [@fade]="stepsService.displayStep6() ? 'in' : 'out'">
                <g filter="url(#filter13)" [class.step-6-animation]="stepsService.displayStep6()">
                    <path d="M542.33 152.255C545.365 148.309 542.552 142.597 537.574 142.597H455.843C453.98 142.597 452.223 143.462 451.087 144.939L371.141 248.876C368.106 252.822 370.919 258.534 375.897 258.534H457.627C459.49 258.534 461.248 257.669 462.383 256.192L542.33 152.255Z"
                          fill="white"/>
                    <path d="M542.33 152.255C545.365 148.309 542.552 142.597 537.574 142.597H455.843C453.98 142.597 452.223 143.462 451.087 144.939L371.141 248.876C368.106 252.822 370.919 258.534 375.897 258.534H457.627C459.49 258.534 461.248 257.669 462.383 256.192L542.33 152.255Z"
                          fill="url(#gradient3)"/>
                    <path d="M450.971 273.563C454.006 269.617 451.193 263.905 446.215 263.905H364.484C362.621 263.905 360.864 264.77 359.728 266.246L279.781 370.183C276.746 374.129 279.559 379.842 284.537 379.842H366.27C368.133 379.842 369.89 378.976 371.026 377.5L450.971 273.563Z"
                          fill="white"/>
                    <path d="M450.971 273.563C454.006 269.617 451.193 263.905 446.215 263.905H364.484C362.621 263.905 360.864 264.77 359.728 266.246L279.781 370.183C276.746 374.129 279.559 379.842 284.537 379.842H366.27C368.133 379.842 369.89 378.976 371.026 377.5L450.971 273.563Z"
                          fill="url(#gradient4)"/>
                    <path d="M450.971 273.563C454.006 269.617 451.193 263.905 446.215 263.905H364.484C362.621 263.905 360.864 264.77 359.728 266.246L279.781 370.183C276.746 374.129 279.559 379.842 284.537 379.842H366.27C368.133 379.842 369.89 378.976 371.026 377.5L450.971 273.563Z"
                          fill="url(#gradient5)"/>
                    <path d="M357.945 394.726C360.98 390.78 358.167 385.068 353.189 385.068H271.457C269.594 385.068 267.837 385.933 266.701 387.409L186.754 491.345C183.719 495.291 186.532 501.003 191.51 501.003H273.242C275.105 501.003 276.862 500.138 277.998 498.661L357.945 394.726Z"
                          fill="white"/>
                    <path d="M357.945 394.726C360.98 390.78 358.167 385.068 353.189 385.068H271.457C269.594 385.068 267.837 385.933 266.701 387.409L186.754 491.345C183.719 495.291 186.532 501.003 191.51 501.003H273.242C275.105 501.003 276.862 500.138 277.998 498.661L357.945 394.726Z"
                          fill="url(#gradient6)"/>
                    <path d="M357.945 394.726C360.98 390.78 358.167 385.068 353.189 385.068H271.457C269.594 385.068 267.837 385.933 266.701 387.409L186.754 491.345C183.719 495.291 186.532 501.003 191.51 501.003H273.242C275.105 501.003 276.862 500.138 277.998 498.661L357.945 394.726Z"
                          fill="url(#gradient7)"/>
                    <path d="M648.076 152.255C651.111 148.309 648.298 142.597 643.32 142.597H561.589C559.727 142.597 557.969 143.462 556.834 144.939L476.887 248.876C473.852 252.822 476.665 258.534 481.643 258.534H563.375C565.238 258.534 566.995 257.669 568.131 256.192L648.076 152.255Z"
                          fill="white"/>
                    <path d="M648.076 152.255C651.111 148.309 648.298 142.597 643.32 142.597H561.589C559.727 142.597 557.969 143.462 556.834 144.939L476.887 248.876C473.852 252.822 476.665 258.534 481.643 258.534H563.375C565.238 258.534 566.995 257.669 568.131 256.192L648.076 152.255Z"
                          fill="url(#gradient8)"/>
                    <path d="M648.076 152.255C651.111 148.309 648.298 142.597 643.32 142.597H561.589C559.727 142.597 557.969 143.462 556.834 144.939L476.887 248.876C473.852 252.822 476.665 258.534 481.643 258.534H563.375C565.238 258.534 566.995 257.669 568.131 256.192L648.076 152.255Z"
                          fill="url(#gradient9)"/>
                    <path d="M753.818 152.255C756.853 148.309 754.04 142.597 749.063 142.597H667.332C665.469 142.597 663.711 143.462 662.576 144.939L582.629 248.876C579.594 252.822 582.407 258.534 587.385 258.534H669.117C670.98 258.534 672.738 257.669 673.873 256.192L753.818 152.255Z"
                          fill="white"/>
                    <path d="M753.818 152.255C756.853 148.309 754.04 142.597 749.063 142.597H667.332C665.469 142.597 663.711 143.462 662.576 144.939L582.629 248.876C579.594 252.822 582.407 258.534 587.385 258.534H669.117C670.98 258.534 672.738 257.669 673.873 256.192L753.818 152.255Z"
                          fill="url(#gradient10)"/>
                    <path d="M753.818 152.255C756.853 148.309 754.04 142.597 749.063 142.597H667.332C665.469 142.597 663.711 143.462 662.576 144.939L582.629 248.876C579.594 252.822 582.407 258.534 587.385 258.534H669.117C670.98 258.534 672.738 257.669 673.873 256.192L753.818 152.255Z"
                          fill="url(#gradient11)"/>
                    <path d="M859.566 152.255C862.601 148.309 859.788 142.597 854.81 142.597H773.078C771.215 142.597 769.458 143.462 768.322 144.939L688.375 248.876C685.34 252.822 688.153 258.534 693.131 258.534H774.864C776.726 258.534 778.484 257.669 779.619 256.192L859.566 152.255Z"
                          fill="white"/>
                    <path d="M859.566 152.255C862.601 148.309 859.788 142.597 854.81 142.597H773.078C771.215 142.597 769.458 143.462 768.322 144.939L688.375 248.876C685.34 252.822 688.153 258.534 693.131 258.534H774.864C776.726 258.534 778.484 257.669 779.619 256.192L859.566 152.255Z"
                          fill="url(#gradient12)"/>
                    <path d="M859.566 152.255C862.601 148.309 859.788 142.597 854.81 142.597H773.078C771.215 142.597 769.458 143.462 768.322 144.939L688.375 248.876C685.34 252.822 688.153 258.534 693.131 258.534H774.864C776.726 258.534 778.484 257.669 779.619 256.192L859.566 152.255Z"
                          fill="url(#gradient13)"/>
                    <path d="M768.205 273.563C771.24 269.617 768.427 263.905 763.449 263.905H681.717C679.854 263.905 678.096 264.77 676.961 266.246L597.016 370.184C593.981 374.129 596.794 379.842 601.771 379.842H683.502C685.365 379.842 687.122 378.976 688.258 377.5L768.205 273.563Z"
                          fill="white"/>
                    <path d="M768.205 273.563C771.24 269.617 768.427 263.905 763.449 263.905H681.717C679.854 263.905 678.096 264.77 676.961 266.246L597.016 370.184C593.981 374.129 596.794 379.842 601.771 379.842H683.502C685.365 379.842 687.122 378.976 688.258 377.5L768.205 273.563Z"
                          fill="url(#gradient14)"/>
                    <path d="M768.205 273.563C771.24 269.617 768.427 263.905 763.449 263.905H681.717C679.854 263.905 678.096 264.77 676.961 266.246L597.016 370.184C593.981 374.129 596.794 379.842 601.771 379.842H683.502C685.365 379.842 687.122 378.976 688.258 377.5L768.205 273.563Z"
                          fill="url(#gradient15)"/>
                    <path d="M675.177 394.726C678.212 390.78 675.4 385.068 670.422 385.068H588.689C586.826 385.068 585.069 385.933 583.933 387.41L503.988 491.345C500.953 495.291 503.766 501.003 508.744 501.003H590.475C592.338 501.003 594.095 500.138 595.231 498.661L675.177 394.726Z"
                          fill="white"/>
                    <path d="M675.177 394.726C678.212 390.78 675.4 385.068 670.422 385.068H588.689C586.826 385.068 585.069 385.933 583.933 387.41L503.988 491.345C500.953 495.291 503.766 501.003 508.744 501.003H590.475C592.338 501.003 594.095 500.138 595.231 498.661L675.177 394.726Z"
                          fill="url(#gradient16)"/>
                    <path d="M675.177 394.726C678.212 390.78 675.4 385.068 670.422 385.068H588.689C586.826 385.068 585.069 385.933 583.933 387.41L503.988 491.345C500.953 495.291 503.766 501.003 508.744 501.003H590.475C592.338 501.003 594.095 500.138 595.231 498.661L675.177 394.726Z"
                          fill="url(#gradient17)"/>
                    <path d="M1069.1 152.255C1072.14 148.309 1069.32 142.597 1064.35 142.597H982.615C980.752 142.597 978.995 143.462 977.859 144.939L897.911 248.876C894.875 252.822 897.688 258.534 902.666 258.534H984.399C986.262 258.534 988.019 257.669 989.155 256.192L1069.1 152.255Z"
                          fill="white"/>
                    <path d="M1069.1 152.255C1072.14 148.309 1069.32 142.597 1064.35 142.597H982.615C980.752 142.597 978.995 143.462 977.859 144.939L897.911 248.876C894.875 252.822 897.688 258.534 902.666 258.534H984.399C986.262 258.534 988.019 257.669 989.155 256.192L1069.1 152.255Z"
                          fill="url(#gradient18)"/>
                    <path d="M1069.1 152.255C1072.14 148.309 1069.32 142.597 1064.35 142.597H982.615C980.752 142.597 978.995 143.462 977.859 144.939L897.911 248.876C894.875 252.822 897.688 258.534 902.666 258.534H984.399C986.262 258.534 988.019 257.669 989.155 256.192L1069.1 152.255Z"
                          fill="url(#gradient19)"/>
                    <path d="M1176.41 152.255C1179.44 148.309 1176.63 142.597 1171.65 142.597H1089.92C1088.06 142.597 1086.3 143.462 1085.16 144.939L1005.22 248.876C1002.18 252.822 1004.99 258.534 1009.97 258.534H1091.7C1093.57 258.534 1095.32 257.669 1096.46 256.192L1176.41 152.255Z"
                          fill="white"/>
                    <path d="M1176.41 152.255C1179.44 148.309 1176.63 142.597 1171.65 142.597H1089.92C1088.06 142.597 1086.3 143.462 1085.16 144.939L1005.22 248.876C1002.18 252.822 1004.99 258.534 1009.97 258.534H1091.7C1093.57 258.534 1095.32 257.669 1096.46 256.192L1176.41 152.255Z"
                          fill="url(#gradient20)"/>
                    <path d="M1176.41 152.255C1179.44 148.309 1176.63 142.597 1171.65 142.597H1089.92C1088.06 142.597 1086.3 143.462 1085.16 144.939L1005.22 248.876C1002.18 252.822 1004.99 258.534 1009.97 258.534H1091.7C1093.57 258.534 1095.32 257.669 1096.46 256.192L1176.41 152.255Z"
                          fill="url(#gradient21)"/>
                    <path d="M1085.04 273.563C1088.08 269.617 1085.27 263.905 1080.29 263.905H998.556C996.694 263.905 994.936 264.77 993.801 266.246L913.855 370.184C910.821 374.129 913.633 379.842 918.611 379.842H1000.34C1002.21 379.842 1003.96 378.976 1005.1 377.5L1085.04 273.563Z"
                          fill="white"/>
                    <path d="M1085.04 273.563C1088.08 269.617 1085.27 263.905 1080.29 263.905H998.556C996.694 263.905 994.936 264.77 993.801 266.246L913.855 370.184C910.821 374.129 913.633 379.842 918.611 379.842H1000.34C1002.21 379.842 1003.96 378.976 1005.1 377.5L1085.04 273.563Z"
                          fill="url(#gradient22)"/>
                    <path d="M1085.04 273.563C1088.08 269.617 1085.27 263.905 1080.29 263.905H998.556C996.694 263.905 994.936 264.77 993.801 266.246L913.855 370.184C910.821 374.129 913.633 379.842 918.611 379.842H1000.34C1002.21 379.842 1003.96 378.976 1005.1 377.5L1085.04 273.563Z"
                          fill="url(#gradient23)"/>
                    <path d="M992.015 394.726C995.05 390.78 992.237 385.068 987.26 385.068H905.529C903.666 385.068 901.909 385.933 900.773 387.409L820.825 491.345C817.79 495.29 820.602 501.003 825.58 501.003H907.315C909.177 501.003 910.935 500.138 912.07 498.661L992.015 394.726Z"
                          fill="white"/>
                    <path d="M992.015 394.726C995.05 390.78 992.237 385.068 987.26 385.068H905.529C903.666 385.068 901.909 385.933 900.773 387.409L820.825 491.345C817.79 495.29 820.602 501.003 825.58 501.003H907.315C909.177 501.003 910.935 500.138 912.07 498.661L992.015 394.726Z"
                          fill="url(#gradient24)"/>
                    <path d="M992.015 394.726C995.05 390.78 992.237 385.068 987.26 385.068H905.529C903.666 385.068 901.909 385.933 900.773 387.409L820.825 491.345C817.79 495.29 820.602 501.003 825.58 501.003H907.315C909.177 501.003 910.935 500.138 912.07 498.661L992.015 394.726Z"
                          fill="url(#gradient25)"/>
                </g>
            </g>

            <g [@fade]="stepsService.displayStep7() ? 'in' : 'out'">
                <path opacity="0.2" d="M968.852 136.491L928.92 185.816V78.6682H968.852V136.491Z" fill="#EC2046"/>
                <path d="M1207.28 132.242H442.26L152.862 511.505H917.879L1207.28 132.242Z" stroke="#8CC640" stroke-miterlimit="10"/>
                <path d="M598.849 306.231H471.692L358.548 454.511H485.705L598.849 306.231Z" stroke="#8CC640" stroke-miterlimit="10"/>
                <path d="M928.879 306.231H801.723L688.578 454.511H815.735L928.879 306.231Z" stroke="#8CC640" stroke-miterlimit="10"/>
                <path d="M365.741 257.534L453.379 143.597H547.726L460.088 257.534H365.741Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M274.38 378.842L362.018 264.905H456.365L368.729 378.842H274.38Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M181.354 500.003L268.993 386.068H363.341L275.703 500.003H181.354Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M471.485 257.534L559.124 143.597H653.471L565.834 257.534H471.485Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M577.23 257.534L664.869 143.597H759.215L671.579 257.534H577.23Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M682.975 257.534L770.613 143.597H864.962L777.323 257.534H682.975Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M591.614 378.842L679.251 264.905H773.599L685.961 378.842H591.614Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M498.588 500.003L586.225 386.068H680.573L592.935 500.003H498.588Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M892.511 257.534L980.152 143.597H1074.5L986.86 257.534H892.511Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M999.815 257.534L1087.46 143.597H1181.8L1094.16 257.534H999.815Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M908.454 378.842L996.091 264.905H1090.44L1002.8 378.842H908.454Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M815.425 500.003L903.065 386.068H997.412L909.776 500.003H815.425Z" fill="white" stroke="#8A9DFF" stroke-width="2"/>
                <path d="M543.433 132H446.563L357.386 247.937H454.256L543.433 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M452.072 253.308H355.202L266.025 369.245H362.897L452.072 253.308Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M359.048 374.471H262.176L172.999 490.406H269.871L359.048 374.471Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M649.177 132H552.307L463.13 247.937H560.002L649.177 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M754.922 132H658.052L568.875 247.937H665.747L754.922 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M860.669 132H763.797L674.62 247.937H771.492L860.669 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M769.306 253.308H672.434L583.259 369.245H680.129L769.306 253.308Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M676.28 374.471H579.408L490.233 490.406H587.103L676.28 374.471Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M1070.2 132H973.335L884.156 247.937H981.028L1070.2 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M1177.51 132H1080.64L991.46 247.937H1088.33L1177.51 132Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M1086.15 253.308H989.275L900.099 369.245H996.97L1086.15 253.308Z" fill="#8A9DFF" fill-opacity="0.2"/>
                <path d="M993.119 374.471H896.249L807.07 490.406H903.944L993.119 374.471Z" fill="#8A9DFF" fill-opacity="0.2"/>
            </g>

            <defs>
                <linearGradient id="gradient0" x1="785.947" y1="217.415" x2="785.947" y2="162.622" gradientUnits="userSpaceOnUse">
                    <!--suppress RequiredAttributes -->
                    <stop stop-color="#44B2E5" stop-opacity="0"/>
                    <stop offset="1" stop-color="#57CBF5"/>
                </linearGradient>
                <filter id="filter0" x="128.562" y="107.942" width="1103.01" height="427.863"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="12.15" result="effect1_foregroundBlur_184_3108"/>
                </filter>
                <radialGradient id="gradient1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(679.998 314.5) rotate(90) scale(271.5 980.556)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(679.998 314.5) rotate(90) scale(271.5 980.556)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <filter id="filter1" x="363.711" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter2" x="272" y="264" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter3" x="179" y="385" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter4" x="680.711" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter5" x="589" y="264" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter6" x="496" y="385" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter7" x="997.711" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter8" x="906" y="264" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter9" x="813" y="385" width="186.047" height="118.937" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter10" x="469" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter11" x="575" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter12" x="890" y="142.597" width="186.047" height="118.937"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.783333 0 0 0 0 0.66107 0 0 0 0 0.567917 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_2602"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_2602" result="shape"/>
                </filter>
                <filter id="filter13" x="181.899" y="138.997" width="999.362" height="365.606"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.8" result="effect1_foregroundBlur_184_1962"/>
                </filter>

                <radialGradient id="gradient3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(456.723 198.312) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient4" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(365.364 319.619) rotate(90) scale(82.9949 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient5" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(365.364 319.619) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient6" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(272.337 440.782) rotate(90) scale(82.9937 173.017)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient7" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(272.337 440.782) rotate(141.715) scale(118.127 60.3528)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient8" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(562.469 198.312) rotate(90) scale(82.9949 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient9" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(562.469 198.312) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient10" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(668.211 198.312) rotate(90) scale(82.9949 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient11" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(668.211 198.312) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient12" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(773.958 198.312) rotate(90) scale(82.9949 173.017)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient13" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(773.958 198.312) rotate(141.715) scale(118.127 60.3533)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient14" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(682.598 319.619) rotate(90) scale(82.9949 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient15" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(682.598 319.619) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient16" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(589.571 440.782) rotate(90) scale(82.9937 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient17" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(589.571 440.782) rotate(141.715) scale(118.126 60.3526)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient18" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(983.493 198.312) rotate(90) scale(82.9949 173.017)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient19" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(983.493 198.312) rotate(141.715) scale(118.127 60.3533)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient20" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(1090.8 198.312) rotate(90) scale(82.9949 173.017)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient21" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(1090.8 198.312) rotate(141.715) scale(118.127 60.3533)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient22" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(999.438 319.619) rotate(90) scale(82.9949 173.015)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient23" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(999.438 319.619) rotate(141.715) scale(118.127 60.3531)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient24" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(906.407 440.782) rotate(90) scale(82.9937 173.017)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.52" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
                <radialGradient id="gradient25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(906.407 440.782) rotate(141.715) scale(118.127 60.3528)">
                    <stop offset="0.135" stop-color="#FBE59C"/>
                    <stop offset="0.564138" stop-color="#FFD64D"/>
                    <stop offset="1" stop-color="#FF9417"/>
                </radialGradient>
            </defs>
        </svg>
    </div>
</main>

<app-address-error-modal [tenantResource]="tenantResource" [calculationId]="calculationId" />
