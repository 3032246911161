import { Injectable } from '@angular/core';
import { Select2Value } from 'ng-select2-component';
import { Observable, Subject } from 'rxjs';

import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
    Module,
    RateRange,
} from '../../../domain/api-result';

export interface CalculationResultData {
    systemPower: number;
    basePrice: number;
    panelPrice: number;
    inverterPrice: number;
    batteryPrice: number;
    totalBatteryPrice: number;
    grantPrice: number;
    systemPrice: number;
    totalPrice: number;
    totalPricePerWp: number;
}

export interface CalculationInfoData {
    apiResultSolarPanelConfiguration:
        | ApiResultSolarPanelConfiguration
        | undefined;
    company: CompanyResponse | undefined;
    modules: Module[] | undefined;
    selectedSolarPanelValue: Select2Value | undefined;
    selectedInverterValue: Select2Value | undefined;
    selectedBatteryTypeValue: Select2Value | undefined;
    batteryEnabled: boolean;
    batteryCount: number | undefined;
}

interface SanitizedCalculationInfoData {
    apiResultSolarPanelConfiguration: ApiResultSolarPanelConfiguration;
    company: CompanyResponse;
    modules: Module[];
    selectedSolarPanelValue: Select2Value;
    selectedInverterValue: Select2Value;
    selectedBatteryTypeValue: Select2Value;
    batteryEnabled: boolean;
    batteryCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class CalculatePricesService {
    private previousData: CalculationInfoData | undefined;

    private calculationSubject$: Subject<CalculationResultData> =
        new Subject<CalculationResultData>();
    private calculationSub$: Observable<CalculationResultData> =
        this.calculationSubject$.pipe();

    private recalculateSubject$: Subject<CalculationInfoData | undefined> =
        new Subject<CalculationInfoData | undefined>();
    private recalculateSub$: Observable<CalculationInfoData | undefined> =
        this.recalculateSubject$.pipe();

    constructor() {
        this.recalculateSub$.subscribe(
            (data: CalculationInfoData | undefined): void => {
                if (undefined === data) {
                    if (undefined === this.previousData) {
                        return;
                    }

                    data = this.previousData;
                }

                if (
                    undefined === data?.apiResultSolarPanelConfiguration ||
                    undefined === data?.company ||
                    undefined === data?.modules ||
                    undefined === data?.selectedSolarPanelValue ||
                    undefined === data?.selectedInverterValue ||
                    undefined === data?.batteryEnabled ||
                    undefined === data?.batteryCount
                ) {
                    return;
                }

                this.previousData = data;

                this.calculationSubject$.next(
                    this.calculatePrices(data as SanitizedCalculationInfoData),
                );
            },
        );
    }

    public forceRecalculate(data: CalculationInfoData | undefined): void {
        this.recalculateSubject$.next(data);
    }

    public calculationSub(): Observable<CalculationResultData> {
        return this.calculationSub$;
    }

    private calculatePrices(
        data: SanitizedCalculationInfoData,
    ): CalculationResultData {
        const systemPower: number = this.systemPower(
            data.apiResultSolarPanelConfiguration,
        );
        const basePrice: number =
            this.pricePerKwh(data.company, systemPower) * systemPower;
        const panelPrice: number = this.panelPrice(
            data.modules,
            data.selectedSolarPanelValue,
        );
        const inverterPrice: number = this.inverterPrice(
            data.modules,
            data.selectedInverterValue,
        );
        const batteryPrice: number = this.batteryPrice(
            data.modules,
            data.selectedBatteryTypeValue,
            data.batteryEnabled,
        );
        const totalBatteryPrice: number = batteryPrice * data.batteryCount;
        const grantPrice: number = this.grantPrice(data.company);
        const systemPrice: number =
            basePrice * 1000 +
            (systemPower * 1000 * (panelPrice + inverterPrice) +
                totalBatteryPrice);
        const totalPrice: number = systemPrice - grantPrice;

        return {
            systemPower: systemPower,
            basePrice: basePrice,
            panelPrice: panelPrice,
            inverterPrice: inverterPrice,
            batteryPrice: batteryPrice,
            totalBatteryPrice: totalBatteryPrice,
            grantPrice: grantPrice,
            systemPrice: systemPrice,
            totalPrice: totalPrice,
            totalPricePerWp: totalPrice / (systemPower * 1000),
        };
    }

    private systemPower(
        apiResultSolarPanelConfiguration:
            | ApiResultSolarPanelConfiguration
            | undefined,
    ): number {
        return apiResultSolarPanelConfiguration?.systemPowerDcKwh ?? 0;
    }

    private pricePerKwh(company: CompanyResponse, systemPower: number): number {
        for (const idx in company.rate_range) {
            const rateRange: RateRange = company.rate_range[idx];
            if (
                null !== rateRange.start &&
                systemPower >= rateRange.start &&
                null !== rateRange.stop &&
                systemPower <= rateRange.stop
            ) {
                return rateRange.rate;
            }

            if (
                null !== rateRange.start &&
                systemPower >= rateRange.start &&
                null === rateRange.stop
            ) {
                return rateRange.rate;
            }

            if (
                null === rateRange.start &&
                null !== rateRange.stop &&
                systemPower <= rateRange.stop
            ) {
                return rateRange.rate;
            }
        }

        const fallback: RateRange | undefined = company.rate_range.find(
            (rateRange: RateRange): boolean =>
                null === rateRange.start && null === rateRange.stop,
        );

        if (undefined !== fallback) {
            return fallback.rate;
        }

        return company.energy_price_for_kwh;
    }

    private findInModules(modules: Module[], value: Select2Value): number {
        const module: Module | undefined = modules.find(
            (module: Module): boolean => module.id === value,
        );

        if (undefined === module) {
            return 0;
        }

        return module.price;
    }

    private panelPrice(
        modules: Module[],
        selectedSolarPanelValue: Select2Value,
    ): number {
        return this.findInModules(modules, selectedSolarPanelValue);
    }

    private inverterPrice(
        modules: Module[],
        selectedInverterValue: Select2Value,
    ): number {
        return this.findInModules(modules, selectedInverterValue);
    }

    private batteryPrice(
        modules: Module[],
        selectedBatteryTypeValue: Select2Value | undefined,
        batteryEnabled: boolean,
    ): number {
        if (!batteryEnabled || undefined === selectedBatteryTypeValue) {
            return 0;
        }

        return this.findInModules(modules, selectedBatteryTypeValue);
    }

    private grantPrice(company: CompanyResponse): number {
        if (!company.dotations_enabled || null === company.dotations_cost) {
            return 0;
        }

        return company.dotations_cost;
    }
}
